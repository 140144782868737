import { useState } from 'react'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Breadcrumbs, Divider, Grid, type GridProps, Stack, ToggleButton, Typography } from '@mui/material'
import { ALL_MATERIALS, COAT, SHIRT, TOPCOAT } from '@trinity/utils'
import { ToggleButtonGroup } from '../../components'

export function MaterialLibrary() {
  return (
    <Stack spacing={4} pt={{ mobile: 1, laptop: 8 }} px={{ mobile: 1, laptop: 4 }}>
      <Typography variant='h2'>Materials Package</Typography>
      <Divider />
      <Box>
        <Outlet />
      </Box>
    </Stack>
  )
}

export function MaterialsList() {
  const navigate = useNavigate()

  return (
    <Grid
      container
      direction={{ mobile: 'column', laptop: 'row' }}
      alignItems='center'
      rowSpacing={{ mobile: 4, laptop: 10 }}
      columnSpacing={10}
    >
      {ALL_MATERIALS.map(material => (
        <Grid item key={material.name} mobile={1} laptop={4} width={1}>
          <Stack
            spacing={2}
            alignItems='center'
            onClick={() => navigate(String(material.id))}
            sx={{
              boxShadow: theme => theme.elevation.card2,
              py: 2,
              border: '2px solid transparent',
              borderRadius: 2,
              ':hover': {
                backgroundColor: 'primary.extraLight',
                border: 'selected',
                cursor: 'pointer',
                '& p': {
                  fontWeight: 600,
                  color: 'primary.main',
                },
                '& hr': {
                  borderColor: 'primary.main',
                },
              },
            }}
          >
            <img src={material.image} alt={material.name} height={300} width={300} />
            <Divider sx={{ width: 1 }} />
            <Typography>{material.name}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}

export function ButtonList({ buttons }: { buttons: TrinityAPI.ButtonType[] }) {
  const [params, setParams] = useSearchParams()
  const defaultGarmentType = params.get('garmentType') ? Number(params.get('garmentType')) : 0
  const [garmentType, setGarmentType] = useState(defaultGarmentType)

  return (
    <Stack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        spacing={4}
        alignItems='center'
        justifyContent='space-between'
      >
        <MaterialBreadcrumbs />
        <ToggleButtonGroup
          value={garmentType}
          onChange={(_e, v) => {
            if (isNumber(v)) {
              setGarmentType(v)
              setParams({ garmentType: String(v) })
            }
          }}
        >
          <ToggleButton value={0}>All</ToggleButton>
          <ToggleButton value={SHIRT.bitmask}>Shirting</ToggleButton>
          <ToggleButton value={COAT.bitmask}>Clothing</ToggleButton>
          <ToggleButton value={TOPCOAT.bitmask}>Outerwear</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <MaterialWrapper>
        {buttons.map(button => (
          <MaterialCard
            key={button.value}
            image={button.image}
            description={button.title}
            code={button.code}
            height='unset'
            width='unset'
          />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function FeltsList({ felts }: { felts: TrinityAPI.FeltType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {felts.map(felt => (
          <MaterialCard key={felt.value} image={felt.image} description={felt.title} code={felt.code} />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function SuedesList({ suedes }: { suedes: TrinityAPI.SuedeType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {suedes.map(suede => (
          <MaterialCard key={suede.value} image={suede.image} description={suede.title} code={suede.code} />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function PipingsList({ pipings }: { pipings: TrinityAPI.PipingType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {pipings.map(piping => (
          <MaterialCard
            key={piping.id}
            image={piping.image}
            description={piping.description}
            code={piping.liningFabricNumber}
          />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function NameLablesList({ labels }: { labels: TrinityAPI.NameLabelType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {labels.map(label => (
          <MaterialCard key={label.value} image={label.image} code={label.description} />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function ThreadsList({ threads }: { threads: TrinityAPI.ThreadType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {threads.map(thread => (
          <MaterialCard
            key={thread.value}
            image={thread.image}
            description={thread.description}
            code={thread.code}
            width='unset'
            height='unset'
          />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function TrouserTrimsList({ trims }: { trims: TrinityAPI.TrouserTrimType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {trims.map(trim => (
          <MaterialCard key={trim.id} image={trim.image} code={trim.description} />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

export function ZippersList({ zippers }: { zippers: TrinityAPI.ZipperType[] }) {
  return (
    <Stack>
      <MaterialBreadcrumbs />
      <MaterialWrapper>
        {zippers.map(zipper => (
          <MaterialCard key={zipper.id} image={zipper.image} code={zipper.description} />
        ))}
      </MaterialWrapper>
    </Stack>
  )
}

function MaterialBreadcrumbs() {
  const navigate = useNavigate()
  const location = useLocation()
  const materialType = location.pathname.split('/')[3] as string
  const displayName = materialType.split('-').join(' ')

  return (
    <Breadcrumbs sx={{ zIndex: 1 }}>
      <Typography variant='link' onClick={() => navigate('/library/materials')}>
        Materials
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }}>{displayName}</Typography>
    </Breadcrumbs>
  )
}

function MaterialWrapper(props: GridProps) {
  return (
    <Grid
      container
      direction={{ mobile: 'column', laptop: 'row' }}
      alignItems='center'
      rowSpacing={{ mobile: 4, laptop: 10 }}
      columnSpacing={10}
      pt={15}
    >
      {props.children}
    </Grid>
  )
}

interface MaterialCardProps {
  image: string
  description?: string
  code: string | null
  height?: number | 'unset'
  width?: number | 'unset'
}

function MaterialCard({ image, description = '', code, height = 120, width = 180 }: MaterialCardProps) {
  return (
    <Grid item mobile={1} laptop={4} width={1}>
      <Stack spacing={2} direction='row' alignItems='center'>
        <img src={image} alt={description} height={height} width={width} />
        <Stack>
          <Typography gutterBottom variant='h5'>
            {code}
          </Typography>
          <Typography variant='smallBody1'>{description}</Typography>
        </Stack>
      </Stack>
    </Grid>
  )
}

const isNumber = (val: unknown): val is number => Number.isInteger(val)
