import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Box, Card, CardMedia, Skeleton, Stack, Typography } from '@mui/material'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { ImageNotFound } from '../../assets'
import { FabricFavorite } from './FabricFavorite'

//! TODO: Refactor to use MUI ImageList

export function FabricCard({ fabric }: { fabric: TrinityAPI.FabricExtendedType }) {
  return (
    <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.25 }}>
      <Link to={`/fabric-explorer/fabrics/${fabric.id}`} style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            height: 318,
            width: 241,
            border: 'n100',
            borderRadius: 2,
            boxShadow: theme => theme.elevation.autocomplete,
          }}
        >
          <CardMedia
            image={fabric.hasImage ? fabric.url : ImageNotFound}
            sx={{
              height: 240,
              width: 240,
              // backgroundSize: fabric.hasImage ? 140 : 'contain',
              // backgroundRepeat: fabric.hasImage ? 'repeat' : 'auto',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              boxShadow: theme => theme.elevation.fabricImage,
            }}
          >
            <Stack direction='row' sx={{ pb: 1.5, pr: 1 }}>
              <AvailabilityIcon inStock={fabric.factories.t2iD?.inStock} />
              <Typography variant='smallBody1' color='grey.A100' sx={{ mt: -0.2, px: 1 }}>
                T2iD
              </Typography>
              <AvailabilityIcon inStock={fabric.factories.iD?.inStock} />
              <Typography variant='smallBody1' color='grey.A100' sx={{ mt: -0.2, px: 1 }}>
                iD
              </Typography>
            </Stack>
          </CardMedia>
          <Stack direction='row' alignItems='center' sx={{ py: 0.5, pl: 2 }}>
            <Typography noWrap variant='h6' sx={{ overflow: 'visible' }}>
              {fabric.trinityFabricNumber}
            </Typography>
            <FabricFavorite fabric={fabric} />
            <Typography
              align='right'
              variant={fabric.discount ? 'smallButton' : 'subtitle1'}
              sx={{
                width: 1,
                bgcolor: fabric.discount ? 'secondary.main' : 'common.white',
                py: fabric.discount ? 1 : 0,
                pr: fabric.discount ? 1 : 2,
                ml: 3,
              }}
            >
              {fabric.discount
                ? `${Math.ceil(Number(fabric.discount))}% off`
                : fabric.priceTier
                  ? '$'.repeat(Number(fabric.priceTier))
                  : ''}
            </Typography>
          </Stack>
          <Typography noWrap variant='smallBody2' sx={{ px: 2, py: 0.5, display: 'inline-block', width: 1 }}>
            {fabric.description}
          </Typography>
        </Card>
      </Link>
    </motion.div>
  )
}

function AvailabilityIcon({ inStock = false }: { inStock?: boolean }) {
  const Icon = inStock ? CheckCircle : Cancel

  return (
    <Box
      sx={{
        height: 10,
        width: 11,
        ml: 2,
        borderRadius: '50%',
        bgcolor: 'common.white',
      }}
    >
      <Icon sx={{ fontSize: 16, color: inStock ? 'success.dark' : 'error.dark', mt: -0.5, mb: 0.4, ml: -0.25 }} />
    </Box>
  )
}

export function SkeletonFabricCard() {
  return (
    <Card
      sx={{
        height: 318,
        width: 241,
        border: 'n100',
        borderRadius: 2,
        boxShadow: theme => theme.elevation.autocomplete,
      }}
    >
      <CardMedia
        image={ImageNotFound}
        sx={{
          height: 240,
          width: 240,
          backgroundSize: 'contain',
          backgroundRepeat: 'auto',
          boxShadow: theme => theme.elevation.fabricImage,
        }}
      />
      <Stack spacing={1} sx={{ px: 2, py: 1 }}>
        <Typography variant='h6'>
          <Skeleton />
        </Typography>
        <Typography variant='smallBody2'>
          <Skeleton />
        </Typography>
      </Stack>
    </Card>
  )
}
