import { useState } from 'react'
import { Typography, Stack, Select, MenuItem, Box, Divider } from '@mui/material'
import { PRICE_PROFILES } from '@trinity/utils'
import { useGlobalState } from '../../hooks'

export function OptionPrices() {
  const { onMobile } = useGlobalState()
  const [currency, setCurrency] = useState<number>(PRICE_PROFILES[0].rate)
  const getPrice = (price: number) => (
    <Typography component='span' variant='h6'>
      {(price * currency).toFixed(2)}
    </Typography>
  )

  return (
    <Stack px={{ xs: 2, md: 8 }} spacing={8}>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={4}>
        <Typography variant='h2'>Option Price List</Typography>
        <Select
          variant='standard'
          labelId='currency'
          id='currency-select'
          value={currency}
          onChange={e => setCurrency(Number(e.target.value))}
          sx={{ width: 120 }}
        >
          {PRICE_PROFILES.map(profile => (
            <MenuItem key={profile.id} value={profile.rate}>
              {profile.title} ({profile.symbol})
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {priceData.map(data => (
        <Stack key={data.title}>
          <Stack
            border='selected'
            minHeight={76}
            justifyContent='center'
            px={4}
            py={{ xs: 2, md: 0 }}
            spacing={{ xs: 2, md: 0 }}
          >
            <Typography variant='h6' color='primary.main'>
              {data.title}
            </Typography>
            {data.subtitle && <Typography variant={onMobile ? 'smallBody3' : 'body3'}>{data.subtitle}</Typography>}
          </Stack>
          <data.Component getPrice={getPrice} onMobile={onMobile} />
        </Stack>
      ))}
    </Stack>
  )
}

interface ChildrenProps {
  children: React.ReactNode
}

export const StackTable = ({ children }: ChildrenProps) => <Stack bgcolor='grey.50'>{children}</Stack>

export const StackTableHead = ({ children }: ChildrenProps) => (
  <Stack direction='row' bgcolor='grey.100' p={1} pl={4} typography='htable' textTransform='uppercase'>
    {children}
  </Stack>
)

export const StackTableBody = ({ children }: ChildrenProps) => (
  <Stack direction='row' alignItems='center' p={2} pl={4} typography='body3'>
    {children}
  </Stack>
)

interface Props {
  getPrice: (price: number) => JSX.Element
  onMobile: boolean
}

function Buttonholes({ getPrice }: Props) {
  const columnFlex = [2, 1]

  return (
    <StackTable>
      <StackTableBody>
        <Box flex={columnFlex[0]}>Single Color</Box>
        <Box flex={columnFlex[1]}>{getPrice(15)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Double Color</Box>
        <Box flex={columnFlex[1]}>{getPrice(20)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Triple Color</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function Buttons({ getPrice, onMobile }: Props) {
  const columnFlex = onMobile ? [1.75, 1, 0.75] : [1, 1, 1]

  return (
    <StackTable>
      <StackTableHead>
        <Box flex={columnFlex[0]}>Premium Levels</Box>
        <Box flex={columnFlex[1]}>Clothing</Box>
        <Box flex={columnFlex[2]}>Shirting</Box>
      </StackTableHead>
      <StackTableBody>
        <Box flex={columnFlex[0]}>Premium</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(4.5)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Premium Plus</Box>
        <Box flex={columnFlex[1]}>{getPrice(15)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(10)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function Coat({ getPrice, onMobile }: Props) {
  const columnFlex = onMobile ? [3, 1] : [2, 1]

  return (
    <StackTable>
      <StackTableBody>
        <Box flex={columnFlex[0]}>
          Chest Piece
          <Typography component='p' variant='smallBody2' sx={{ mt: 1, maxWidth: '90%' }}>
            Upgrade To Full Canvas From Half Canvas
          </Typography>
        </Box>
        <Box flex={columnFlex[1]}>{getPrice(50)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Interior Shape - Curved French Facing</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Jacket Sleeve Vent Grosgain</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Classic Outerwear Chesterfield Collar</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function FormalTreatment({ getPrice, onMobile }: Props) {
  const columnFlex = onMobile ? [1.75, 1, 0.75] : [1, 1, 1]

  return (
    <StackTable>
      <StackTableBody>
        <Box flex={columnFlex[0]}>Jacket</Box>
        <Box flex={columnFlex[1]}>{getPrice(60)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Vest</Box>
        <Box flex={columnFlex[1]}>{getPrice(15)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Pant</Box>
        <Box flex={columnFlex[1]}>{getPrice(15)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function Linings({ getPrice, onMobile }: Props) {
  const columnFlex = [1, 1, 1, 1, 1, 1]

  if (onMobile) return <MobileLinings onMobile getPrice={getPrice} />

  return (
    <StackTable>
      <StackTableHead>
        <Box flex={columnFlex[0]}>Price Code</Box>
        <Box flex={columnFlex[1]}>Jacket</Box>
        <Box flex={columnFlex[2]}>Vest</Box>
        <Box flex={columnFlex[3]}>Outerwear</Box>
        <Box flex={columnFlex[4]}>3 Piece Suit</Box>
        <Box flex={columnFlex[5]}>Casual Jacket</Box>
      </StackTableHead>
      <StackTableBody>
        <Box flex={columnFlex[0]}>L1</Box>
        <Box flex={columnFlex[1]}>
          <strong>Free</strong>
        </Box>
        <Box flex={columnFlex[2]}>
          <strong>Free</strong>
        </Box>
        <Box flex={columnFlex[3]}>
          <strong>Free</strong>
        </Box>
        <Box flex={columnFlex[4]}>
          <strong>Free</strong>
        </Box>
        <Box flex={columnFlex[5]}>
          <strong>Free</strong>
        </Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L2</Box>
        <Box flex={columnFlex[1]}>{getPrice(6)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(6)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(6)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(12)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(6)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L3</Box>
        <Box flex={columnFlex[1]}>{getPrice(12)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(12)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(12)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(24)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(12)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L4</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(25)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(25)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(50)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(25)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L5</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(25)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(25)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(50)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(25)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L6</Box>
        <Box flex={columnFlex[1]}>{getPrice(80)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(80)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(80)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(160)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(80)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L7</Box>
        <Box flex={columnFlex[1]}>{getPrice(30)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(30)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(30)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(60)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(30)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Custom</Box>
        <Box flex={columnFlex[1]}>{getPrice(50)}</Box>
        <Box flex={columnFlex[2]}>{getPrice(50)}</Box>
        <Box flex={columnFlex[3]}>{getPrice(90)}</Box>
        <Box flex={columnFlex[4]}>{getPrice(65)}</Box>
        <Box flex={columnFlex[5]}>{getPrice(50)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function MobileLinings({ getPrice }: Props) {
  const columnFlex = [1.5, 1]

  return (
    <StackTable>
      <StackTableHead>
        <Box height={55} display='flex' alignItems='center'>
          Jacket / Vest / Casual Jacket
        </Box>
      </StackTableHead>
      <StackTableBody>
        <Box flex={columnFlex[0]}>L1</Box>
        <Typography variant='h6' flex={columnFlex[1]}>
          Free
        </Typography>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L2</Box>
        <Box flex={columnFlex[1]}>{getPrice(6)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L3</Box>
        <Box flex={columnFlex[1]}>{getPrice(12)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L4</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L5</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L6</Box>
        <Box flex={columnFlex[1]}>{getPrice(80)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L7</Box>
        <Box flex={columnFlex[1]}>{getPrice(30)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Custom</Box>
        <Box flex={columnFlex[1]}>{getPrice(50)}</Box>
      </StackTableBody>
      <StackTableHead>
        <Box height={55} display='flex' alignItems='center'>
          Outerwear
        </Box>
      </StackTableHead>
      <StackTableBody>
        <Box flex={columnFlex[0]}>L1</Box>
        <Typography variant='h6' flex={columnFlex[1]}>
          Free
        </Typography>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L2</Box>
        <Box flex={columnFlex[1]}>{getPrice(6)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L3</Box>
        <Box flex={columnFlex[1]}>{getPrice(12)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L4</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L5</Box>
        <Box flex={columnFlex[1]}>{getPrice(25)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L6</Box>
        <Box flex={columnFlex[1]}>{getPrice(80)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L7</Box>
        <Box flex={columnFlex[1]}>{getPrice(30)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Custom</Box>
        <Box flex={columnFlex[1]}>{getPrice(90)}</Box>
      </StackTableBody>
      <StackTableHead>
        <Box height={55} display='flex' alignItems='center'>
          3 Piece Suit
        </Box>
      </StackTableHead>
      <StackTableBody>
        <Box flex={columnFlex[0]}>L1</Box>
        <Typography variant='h6' flex={columnFlex[1]}>
          Free
        </Typography>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L2</Box>
        <Box flex={columnFlex[1]}>{getPrice(12)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L3</Box>
        <Box flex={columnFlex[1]}>{getPrice(24)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L4</Box>
        <Box flex={columnFlex[1]}>{getPrice(50)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L5</Box>
        <Box flex={columnFlex[1]}>{getPrice(50)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L6</Box>
        <Box flex={columnFlex[1]}>{getPrice(160)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>L7</Box>
        <Box flex={columnFlex[1]}>{getPrice(60)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Custom</Box>
        <Box flex={columnFlex[1]}>{getPrice(65)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function Pant({ getPrice, onMobile }: Props) {
  const columnFlex = onMobile ? [3, 1] : [2, 1]

  return (
    <StackTable>
      <StackTableBody>
        <Box flex={columnFlex[0]}>Interior Waistband, Pocketing, Or Both</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function Shirt({ getPrice, onMobile }: Props) {
  const columnFlex = onMobile ? [3, 1] : [2, 1]

  return (
    <StackTable>
      <StackTableBody>
        <Box flex={columnFlex[0]}>
          Accent Trim
          <Typography component='p' variant='smallBody2' sx={{ mt: 1, maxWidth: '75%' }}>
            Flat Rate Per Shirt For As Many Locations
          </Typography>
        </Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>
          Fabric Direction
          <Typography component='p' variant='smallBody2' sx={{ mt: 1, maxWidth: '75%' }}>
            Flat Rate Per Shirt, Regardless Of The Number Of Locations Or Combination Of Directions Selected
          </Typography>
        </Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>Pocket Square With {onMobile && <br />} Tie Keeper</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
      <Divider variant='middle' />
      <StackTableBody>
        <Box flex={columnFlex[0]}>
          Signature Monogram {onMobile && <br />} (Per Shirt)
          <Typography component='p' variant='smallBody2' sx={{ mt: 1, maxWidth: '75%' }}>
            Set Up Fee Per Customer is {getPrice(25)}
          </Typography>
        </Box>
        <Box flex={columnFlex[1]}>{getPrice(3)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

function Vest({ getPrice, onMobile }: Props) {
  const columnFlex = onMobile ? [3, 1] : [2, 1]

  return (
    <StackTable>
      <StackTableBody>
        <Box flex={columnFlex[0]}>Shell Fabric</Box>
        <Box flex={columnFlex[1]}>{getPrice(10)}</Box>
      </StackTableBody>
    </StackTable>
  )
}

//* HELPERS
const priceData = [
  {
    title: 'Buttons',
    subtitle: 'Button surcharge is only applied once per button type per ID number regardless of the garment type',
    Component: Buttons,
  },
  {
    title: 'Formal Treatment',
    subtitle: 'Price covers all possible locations per garment type',
    Component: FormalTreatment,
  },
  {
    title: 'Jacket Milanese Button Holes',
    subtitle: 'Single color included in the price of a handsewn jacket (Mayfair)',
    Component: Buttonholes,
  },
  {
    title: 'Linings',
    subtitle:
      'Price per each lining selected if different linings are used for the back location, or back strap location',
    Component: Linings,
  },
  { title: 'Jacket / Outerwear Options', subtitle: null, Component: Coat },
  { title: 'Shirt Options', subtitle: null, Component: Shirt },
  { title: 'Pant Accent Trim', subtitle: 'Covers Interior Waistband, Pocketing, Or Both', Component: Pant },
  { title: 'Vest Back Options', subtitle: null, Component: Vest },
]
